import { html, LitElement, css, PropertyValues, nothing, TemplateResult } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import observeRect from '@reach/observe-rect'


@customElement('searchable-multi-select')
export class SearchableMultiSelect extends LitElement {
  @property()
  selectElement: HTMLSelectElement

  @property()
  dropdownShown: boolean

  @property()
  query: string = ''

  @property()
  hoveredOptionId: string

  @property()
  width: Number

  @property()
  top: Number

  @property()
  left: Number
  
  static styles = css`
    div.multiselect {
      position: relative;
      width: 100%;
    }

    div.container {
      background-color: white;
      padding: 8px;
      border-color: rgb(203,213,225);
      border-style: solid;
      border-width: 1px;
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    }

    input[type='text'] {
      border: none;
      overflow: auto;
      outline: none;
      resize: none;
      flex: 1 1 auto;
      font-size: 16px;
      width: 0;
    }

    div.menu-options {
      background-color: white;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      border-bottom-right-radius: 0.375rem; /* 6px */
      border-bottom-left-radius: 0.375rem; /* 6px */
      border-width: 1px;
      border-style: solid;
      border-color: rgb(203,213,225);
      border-top-width: 0;
      overflow: hidden;
    }

    div.option {
      padding-left: 4px;
      padding-right: 4px;
      cursor: pointer;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    div.option.hovering {
      background-color: #3b82f6 !important;
      color: white;
    }

    div.option.selected {
      background-color: #cbd5e1;
    }
  `

  constructor() {
    super();
    this.selectElement = this.querySelector('select')
  }

  firstUpdated() {
    const containerElement = this.shadowRoot.querySelector('div.container')
    observeRect(containerElement, rect => {
      this.width = rect.width
      this.top = rect.bottom
      this.left = rect.left
    }).observe()
  }

  getSelectedOptions() {
    let out = []
    for (const option of this.selectElement.options) {
      if (!option.selected) { continue }
      out.push(html`<select-option name="${option.innerText}" value="${option.value}"></select-option>`)
    }
    return out
  }

  unselectOption(e: Event) {
    const optionToRemove = e.target as ListItem
    for (const option of this.selectElement.options) {
      if (option.value != optionToRemove.value) { continue }
      option.selected = false
      break
    }
    this.requestUpdate()
  }

  onInputFocused(e: Event) {
    this.dropdownShown = true
  }

  onInputBlured(e: Event) {
    this.query = ""
    this.dropdownShown = false
  }

  onOptionHover(e: Event) {
    const target = e.target as LitElement
    // @ts-ignore
    const option = target.option as HTMLOptionElement
    this.hoveredOptionId = target.id
  }

  onInputKeyDown(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement
    const id = parseInt(this.hoveredOptionId.split('_')[1])
    const mod = this.selectElement.options.length
    
    if (e.key === 'ArrowDown') {
      this.hoveredOptionId = `option_${(((id+1) % mod) + mod) % mod}`
    } else if (e.key === 'ArrowUp') {
      this.hoveredOptionId = `option_${(((id-1) % mod) + mod) % mod}`
    } else if (e.key === 'Escape') {
      target.blur()
      this.dropdownShown = false
    } else if (e.key === 'Enter') {
      const optionIndex = parseInt(this.hoveredOptionId.split('_')[1])
      const targetOption = this.selectElement.options[optionIndex]
      this.selectOption(targetOption)
      this.query = ""
    }
  }

  renderPopup() {
    return html `
    <div id="dropdownArea" style="overflow: auto; max-height: 15rem; position: fixed; width: ${this.width}px; z-index: 1100; top: ${this.top}px; left: ${this.left}px;" ?hidden=${!this.dropdownShown} >
      <div class="menu-options">
        ${this.renderOptionss()}
      </div>
    </div>
    `
  }

  selectOption(targetOption: HTMLOptionElement) {
    for (const option of this.selectElement.options) {
      if (option.value !== targetOption.value) { continue }
      option.selected = !option.selected
      break
    }
    this.hoveredOptionId = "option_0"
    this.requestUpdate()
  }

  onOptionClicked(e: Event) {
    const target = e.target as HTMLDivElement
    this.hoveredOptionId = target.id
    // @ts-ignore
    const targetOption = target.option
    this.selectOption(targetOption)    
  }

  // When the user clicks anywhere in the white area, focus the input
  onContainerClicked(e: Event) {
    if (!(e.target instanceof HTMLDivElement)) { return }
    if (!e.target.classList.contains('container')) { return }
    const inputElement = e.target.querySelector("input[type='text']") as HTMLInputElement
    inputElement.focus()
  }


  // We use renderOptionss because renderOptions name collides with something
  renderOptionss() {
    let filteredOptions = []
    let i = 0
    let anyOptionSelected = false
    for (const option of this.selectElement.options) {
      if (option.innerText.toUpperCase().includes(this.query.toUpperCase())) {
        option.dataset.id = `option_${i}`
        if (option.dataset.id == this.hoveredOptionId) { anyOptionSelected = true }
        filteredOptions.push(option)
      }
      i++;
    }
    
    let out = []

    let firstOption = true
    for (const option of filteredOptions) {
      let classes = { hovering: (option.dataset.id == this.hoveredOptionId), selected: (option.selected) }
      if (firstOption && !anyOptionSelected) {
        firstOption = false
        classes.hovering = true
        this.hoveredOptionId = option.dataset.id
      }
      out.push(html`
        <div id=${option.dataset.id} @mouseenter=${this.onOptionHover} @mousedown=${this.onOptionClicked}
        class="option ${classMap(classes)}" .option=${option}>
          ${option.innerText}
        </div>
      `)
    }
    return out
  }

  render() {
    return html`
      <div class="multiselect">
        <div class="container" @click=${this.onContainerClicked} @option-removed=${this.unselectOption}>
          ${this.getSelectedOptions()}
          <input .value=${this.query} @input=${e => {this.query = e.target.value}} @keydown=${this.onInputKeyDown} @focus=${this.onInputFocused} @blur=${this.onInputBlured}  type="text" />
        </div>
        ${this.renderPopup()}
      </div>
    `
  }
}

@customElement('select-option')
class ListItem extends LitElement {
  static styles = css`
    div.item {
      background-color: #e5e7eb;
      padding-left: 0;
      padding-right: 0;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: #94a3b8;
      display: flex;
      align-items: center;
    }

    span.remove-button {
      width: 8px;
      border-right-style: solid;
      border-width: 1px;
      padding-left: 4px;
      padding-right: 4px;
      border-color: #94a3b8;
      cursor: pointer;
    }

    span.display-area {
      padding-left: 4px;
      padding-right: 4px;
    }
  `

  @property()
  name: string

  @property()
  value: string

  _deleteClicked() {
    this.dispatchEvent(new Event('option-removed', {bubbles: true}))
  }

  render() {
    return html`
    <div data-value="${this.value}" class="item">
      <span @click=${this._deleteClicked} class="remove-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
      </span>
      <span class="display-area">${this.name}</span>
    </div>
  `
  }
}